<template>
    <div class="setting-items-layout">
        <el-form-item prop="isResultSend">
            <span slot="label" class="label">结果发送</span>
            <el-switch :disabled="tourDetailsClone.type == 'detail'" v-model="ruleForm.isResultSend"></el-switch>
            <span class="text">发送异常通知至教师</span>
        </el-form-item>
        <el-form-item prop="isExcellentExcellent">
            <span slot="label" class="label">优秀推荐</span>
            <el-switch :disabled="tourDetailsClone.type == 'detail'" v-model="ruleForm.isExcellentExcellent"></el-switch>
            <span class="text">发送至校园公告</span>
        </el-form-item>
    </div>
</template>

<script>
    export default {
        name: "SettingItems",
        props: {
            ruleForm: Object,
            tourDetailsClone: Object
        },
    }
</script>

<style lang="scss" scoped>
    .setting-items-layout {
            margin-top: 20px;
        .text {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #B6BABF;
            margin-left: 16px;
        }
    }
</style>