<template>
    <div class="form-title">
        <span class="line"></span>
        <span class="title">{{ title }}</span>
    </div>
</template>
<script>
export default {
    name: "ClassPatrolTempFormTitle",
    props: {
        title: String
    }
}
</script>
<style lang="scss" scoped>
    .form-title {
        display: inline-block;
        .line {
            width: 3px;
            height: 14px;
            background: #4595E6;
            margin-top: 8px;
            margin-right: 8px;
            display: inline-block;
        }
        .title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #363B40;
        }

    }
</style>