<template>
    <div class="teacher-evaluation-wrapper">
        <div
            v-for="(item, index) of data.labelList"
            :key="index"
        >
            <template v-if="item.labelType == '2'">
                <div class="form-item">
                    <el-tooltip
                        effect="dark"
                        :content="item.labelName"
                        placement="top-start"
                    >
                        <span class="label">{{
                            index + 1 + "." + item.labelName
                        }}</span>
                    </el-tooltip>
                    <el-checkbox-group
                        v-model="item.value"
                        class="sel"
                        @change="handlerChangeDeep"
                        :disabled="tourDetailsClone.type == 'detail'"
                    >
                        <el-checkbox
                            v-for="subItem in item.dataList"
                            :label="subItem.dataName"
                            :key="subItem.id"
                            :value="subItem.id"
                        >
                            {{ subItem.dataName }}
                        </el-checkbox>
                        <el-input
                            class="input"
                            maxlength="100"
                            v-if="item.value.includes(other)"
                            v-model="item.otherDesc"
                            @input="handlerChangeDeep"
                            :disabled="tourDetailsClone.type == 'detail'"
                        ></el-input>
                    </el-checkbox-group>
                </div>
            </template>
            <template v-if="item.labelType == '1'">
                <div class="form-item">
                    <el-tooltip
                        effect="dark"
                        :content="item.labelName"
                        placement="top-start"
                    >
                        <span class="label">{{
                            index + 1 + "." + item.labelName
                        }}</span>
                    </el-tooltip>
                    <el-radio-group
                        v-model="item.value"
                        class="sel"
                        @change="handlerChangeDeep"
                        :disabled="tourDetailsClone.type == 'detail'"
                    >
                        <el-radio
                            v-for="subItem in item.dataList"
                            :key="subItem.id"
                            :label="subItem.dataName"
                            :value="subItem.id"
                            >{{ subItem.dataName }}</el-radio
                        >
                        <el-input
                            class="input"
                            maxlength="100"
                            v-if="item.value == other"
                            v-model="item.otherDesc"
                            @input="handlerChangeDeep"
                            :disabled="tourDetailsClone.type == 'detail'"
                        ></el-input>
                    </el-radio-group>
                </div>
            </template>
            <template>
                <div class="abnormal-wrapper">
                    <el-checkbox
                        v-if="num == '0'"
                        class="abnormal"
                        v-model="item.isAbnormal"
                        @change="handlerChangeDeep"
                        :disabled="tourDetailsClone.type == 'detail'"
                    >
                        <span class="label">异常</span>
                    </el-checkbox>
                    <RichEditor
                        v-model="item.otherInformation"
                        height="100px"
                        placeholder="请输入内容"
                        :disabled="tourDetailsClone.type == 'detail'"
                        @input="handlerChangeDeep"
                        @change="handlerChangeDeep"
                    />
                </div>
            </template>
            <template>
                <div
                    v-if="item.abnormalResultFeedback"
                    class="abnormal-results-counterintuitive"
                >
                    <span class="label">* 异常结果反馈</span>
                    <span class="value">{{ item.abnormalResultFeedback }}</span>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import RichEditor from "./RichEditor.vue";
export default {
    name: "TeacherEvaluation",
    props: {
        data: Object,
        tourDetailsClone: Object,
        num: Number,
    },
    components: {
        RichEditor,
    },
    data() {
        return {
            other: "其他",
        };
    },
    methods: {
        handlerChangeDeep() {
            this.$eventDispatch("handlerChangeDeep");
        },
    },
};
</script>
<style lang="scss" scoped>
.teacher-evaluation-wrapper {
    background-color: #fafafa;
    box-sizing: border-box;
    padding: 24px 26px;
    .form-item {
        min-height: 60px;
        display: flex;
        // align-items: center;
        justify-content: center;
        padding: 0 10px;
        box-sizing: border-box;
        padding: 10px 0px;
        flex-direction: column;
        .label {
            // width: 70px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // border-right: 1px solid #c8cacc;
            color: #808387;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            box-sizing: border-box;
            padding-right: 10px;
        }
        .sel {
            flex: 1;
            // padding-left: 10px;
            display: flex;
            flex-wrap: wrap;
            label {
                margin: 5px;
            }
        }
        .input {
            width: 400px;
        }
    }
    .abnormal-wrapper {
        display: flex;
        box-sizing: border-box;
        .abnormal {
            width: 100px;
            .label {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #808387;
            }
        }
    }
}
.abnormal-results-counterintuitive {
    display: flex;
    margin: 20px 0;
    .label {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-right: 1px solid #c8cacc;
        color: #df404c;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        box-sizing: border-box;
        padding-right: 10px;
    }
    .value {
        width: 460px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        box-sizing: border-box;
        padding: 0 10px;
    }
}
</style>
