<template>
    <div class="form-tabs clear-fixed">
        <div class="vertical-line"></div>
        <div 
            v-for="(item) of formTabsList" 
            :key="item.id"
            class="tab-item"
            @click="changeFormTabs(item)"
        >
            <span 
                :class="['title', {current: formTabsPointer == item.id }]"
            >
                {{ item.title }}
            </span>
            <span 
                v-if="formTabsPointer == item.id"
                class="line"
            ></span>
        </div>
    </div>
</template>
<script>
    export default {
        name: "FormTabs",
        props: {
            formTabsList: Array,
            formTabsPointer: String
        },
        methods: {
            changeFormTabs (item) {
                this.$eventDispatch('changeFormTabs', item)
            }
        }
    }
</script>

<style lang="scss">
    .form-tabs {
        display: inline-block;
        margin-top: 7px;
        .vertical-line {
            width: 1px;
            height: 16px;
            margin-left: 20px;
            background: #DBDBDB;
            float: left;
        }

        .tab-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            float: left;

            .title {
                // line-height: 35px;
                margin: 0px 14px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #9DA2A6;
                cursor: pointer;
            }

            .title.current {
                color: #363B40;
            }

            .line {
                width: 28px;
                height: 2px;
                background: #363B40;
                border-radius: 1px;
                margin: 10px 0 0 0;
            }
        }
        .tab-item.current {

        }

    }

    .clear-fixed :after{
        content: "";
        display: table;
        clear: both;
    }
</style>